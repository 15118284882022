import { PlusOutlined } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Divider, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { DocumentNode } from 'graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ClientType, useAddClientMutation } from '@/generated/graphql';
import useUser from '@/hooks/useUser';

import Box from '../Box';
const { Option, OptGroup } = Select;

interface Item {
  id: string;
  value: string;
  type?: string;
}

interface IState {
  timeout?: number;
  dataSource: Item[];
  searchString: string;
}

export interface Props<T = LabeledValue | LabeledValue[]> {
  query: DocumentNode;
  label?: string;
  dropdownClassName?: string;
  returnName: string;
  value?: LabeledValue | LabeledValue[];
  onChange?: (key?: T) => void;
  style?: React.CSSProperties;
  typeTag?: boolean;
  autoFocus?: boolean;
  clientType?: string;
  onBlur?: () => void;
  filter?: (values: Item[]) => Item[];
  addItem?: boolean;
  disabled?: boolean;
  regionAndStreets?: boolean;
  withStreets?: boolean;
  size?: 'small' | 'middle' | 'large';
  clientId?: string;
  className?: string;
}

export const AutoCompleteField = ({
  addItem: enableAddItems = false,
  autoFocus,
  clientType,
  filter = values => values,
  label = '',
  onBlur,
  onChange = () => undefined,
  query,
  returnName = 'fetchPlaces',
  style,
  typeTag,
  value: values,
  disabled = false,
  regionAndStreets = false,
  withStreets = true,
  size = 'middle',
  clientId,
  dropdownClassName,
  className = '',
}: Props): JSX.Element => {
  const user = useUser();
  const [state, setState] = useState<IState>({
    timeout: undefined,
    dataSource: [],
    searchString: '',
  });
  const { t } = useTranslation();
  const client = useApolloClient();
  const [action] = useAddClientMutation();

  const handleSearch = (value: string): void => {
    const { timeout } = state;

    setState(prev => ({
      ...prev,
      searchString: value,
    }));

    if (value.length > (user?.role === 'admin' ? 0 : 2)) {
      const later = (): void => {
        setState(prev => ({
          ...prev,
          timeout: undefined,
        }));
        client
          .query({
            query: query,
            variables: {
              key: value,
              clientType: clientType,
              clientId,
            },
          })
          .then(({ data }) => {
            const dataSource = data ? filter(data[returnName]) : [];

            setState(prev => ({
              ...prev,
              dataSource: dataSource,
            }));
          });
      };
      if (timeout) {
        clearTimeout(timeout);
      }

      setState(prev => ({
        ...prev,
        timeout: window.setTimeout(later, 150),
      }));
    } else {
      setState(prev => ({
        ...prev,
        dataSource: [],
      }));
    }
  };

  const addItem = (name: string): void => {
    action({
      variables: {
        input: {
          name,
          type: ClientType.Tenant,
        },
      },
    }).then(({ data }) => {
      if (Array.isArray(values)) {
        onChange([
          ...values,
          { value: data.addClient.id, label: data.addClient.name },
        ]);
      } else {
        onChange({
          value: data.addClient.id,
          label: data.addClient.name,
        });
      }
    });
  };

  const onSelect = (): void => {
    setState(prev => ({
      ...prev,
      dataSource: [],
    }));
  };

  const commerce = state.dataSource.filter(p => p.type === 'commerce');
  const district = state.dataSource.filter(p => p.type === 'district');
  const districtArea = state.dataSource.filter(p => p.type === 'district_area');
  const city = state.dataSource.filter(p => p.type === 'city');
  const municipality = state.dataSource.filter(p => p.type === 'municipality');
  const county = state.dataSource.filter(p => p.type === 'county');
  const street = withStreets
    ? state.dataSource.filter(p => p.type === 'street')
    : null;
  const other = state.dataSource.filter(p => p.type === 'other');
  const regionsAndStreetsValues: {
    value: Item[];
    label: string;
  }[] = [
    {
      value: county,
      label: t('county'),
    },
    {
      value: municipality,
      label: t('municipality'),
    },
    {
      value: city,
      label: t('city'),
    },

    {
      value: district,
      label: t('district'),
    },
    {
      value: districtArea,
      label: t('district_area'),
    },
    {
      value: commerce,
      label: t('commerce'),
    },
    {
      value: other,
      label: t('other'),
    },
  ];

  if (withStreets) {
    regionsAndStreetsValues.push({
      value: street,
      label: t('street'),
    });
  }

  return (
    <Select<Props['value']>
      labelInValue
      showSearch
      allowClear
      className={className}
      value={values}
      mode={typeTag ? 'multiple' : undefined}
      autoFocus={autoFocus}
      filterOption={false}
      dropdownClassName={dropdownClassName}
      onSelect={onSelect}
      disabled={disabled}
      onChange={value => {
        if (typeTag && Array.isArray(value)) {
          onChange(value);
        } else if (!typeTag && !Array.isArray(value) && value) {
          onChange(value);
        }
        if (!value) {
          onChange(undefined);
        }
      }}
      onSearch={value => {
        handleSearch(value);
      }}
      placeholder={
        <Box flex alignItems={'center'}>
          <>{label}</>
          <SearchOutlined style={{ marginLeft: 'auto' }} />
        </Box>
      }
      showArrow={false}
      style={style}
      notFoundContent={null}
      onBlur={onBlur}
      dropdownRender={menu => (
        <div>
          {menu}
          {enableAddItems && state.searchString.length > 2 && (
            <>
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{ padding: '4px 8px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={() => addItem(state.searchString)}
              >
                <PlusOutlined /> Lägg till {`"${state.searchString}"`}
              </div>
            </>
          )}
        </div>
      )}
      size={size}
    >
      {enableAddItems && state.dataSource.length === 0 && (
        <Option disabled value="none" key="none">
          {state.searchString.length > 2
            ? t('No hit')
            : t('Write three letters to search')}
        </Option>
      )}
      {regionAndStreets ? (
        <>
          {regionsAndStreetsValues.map(
            ({ value, label }) =>
              value.length > 0 && (
                <OptGroup label={label} key={label}>
                  {value.map(option => (
                    <Option value={option.id} key={option.id}>
                      {option.value}
                    </Option>
                  ))}
                </OptGroup>
              ),
          )}
        </>
      ) : (
        state.dataSource.map(option => (
          <Option value={option.id} key={option.id}>
            {option.value}
          </Option>
        ))
      )}
    </Select>
  );
};
