import { Form } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AutoCompleteField,
  Props as AutoCompleteProps,
} from '@/components/AutoCompleteField';
import {
  RegionAndStreetAutocompleteDocument,
  RegionAutocompleteDocument,
} from '@/generated/graphql';

interface ForwardProps {
  handleChange: () => void;
}

// eslint-disable-next-line react/display-name
const WrappedAutocomplete = React.forwardRef<
  typeof AutoCompleteField,
  ForwardProps & AutoCompleteProps
  // eslint-disable-next-line react/prop-types
>(({ handleChange, onChange, ...props }) => (
  <AutoCompleteField
    onChange={v => {
      onChange(v);
      handleChange();
    }}
    {...props}
  />
));

interface Props {
  concealed?: boolean;
  handleSearch?: () => void;
  autoFocus?: boolean;
  size?: SizeType;
}

function RegionAutocomplete({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleSearch = () => {},
  concealed = false,
  autoFocus = true,
  size = 'large',
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="regionIds"
      shouldUpdate
      style={{ display: concealed ? 'none' : 'block' }}
    >
      <WrappedAutocomplete
        withStreets={false}
        autoFocus={autoFocus}
        regionAndStreets
        query={RegionAndStreetAutocompleteDocument}
        label={t('Area or street')}
        returnName={'regionsAndStreets'}
        typeTag
        handleChange={() => handleSearch()}
        size={size}
      />
    </Form.Item>
  );
}

export default RegionAutocomplete;
